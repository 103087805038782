<template>
    <div>
        <!--Page Title-->
        <section class="page-banner" style="background-image:url(/images/background/bg-banner-1.jpg);">
            <div class="top-pattern-layer"></div>
        </section>
        <!--End Page Banner-->
        <div class="sidebar-page-container">
            <div class="auto-container">
                <div class="row clearfix">
                    <!--Content Side / Blog Detail-->
                    <div class="content-side col-lg-8 col-md-12 col-sm-12">
                        <div class="blog-detail">
                            <div class="">
                                <figure class="image">
                                    <div class="dragon-avatar">
                                        <img :src="getAvatar(dragon, i)" v-for="i in 4" :key="i" :alt="dragon.name" :title="dragon.name" @error="getErrorImg">
                                    </div>
                                </figure>
                            </div>
                            <div class="lower-content">              
                                <h3>{{ dragon.name }}</h3>
                                
                                <p>{{ dragon.description || 'Description is updating ...' }}</p>
                            </div>
                        </div>

                        <div class="post-share-options clearfix">
                            <div class="social-links">
                                <ul class="default-social-links clearfix">
                                    <li><a href="https://dragoncityz.com"><span class="fab fa-twitter"></span></a></li>
                                    <li><a href="https://dragoncityz.com"><span class="fab fa-facebook-square"></span></a>
                                    </li>
                                    <li><a href="https://dragoncityz.com"><span class="fab fa-pinterest-p"></span></a>
                                    </li>
                                    <li><a href="https://dragoncityz.com"><span class="fab fa-instagram"></span></a></li>
                                </ul>
                            </div>
                        </div>

                        <section class="games-page-section">
                            <div class="">
                                <!--Game Details-->
                                <div class="game-details">
                                    <div class="inner">
                                        <div class="lower-content">
                                            <div class=" links-widget clearfix">
                                                <div class="widget-content">
                                                    <div class="row clearfix">
                                                        <div class="column col-lg-6 col-md-6 col-sm-12">
                                                            <div class="widget-title">
                                                                <h3>Dragon details</h3>
                                                            </div>
                                                            <table class="tbl-info">
                                                                <tr>
                                                                    <td>Dragon Code</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ dragon.id }}</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Release Date</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ dragon.releaseDate || '...' }}</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Category</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ dragon.category }}</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Breedable</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ dragon.breedable ? 'Yes' : 'No' }}</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Available in Shop</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ dragon.inStore ? 'Yes' : 'No' }}</a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div class="column col-lg-6 col-md-6 col-sm-12">
                                                            <div class="widget-title">
                                                                <h3>&nbsp;</h3>
                                                            </div>
                                                            <table class="tbl-info">
                                                                <tr>
                                                                    <td>Price Gem</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ numberFormat(dragon.priceGem || 0) }} gem(s)</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Hatching Time</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ convertSecondsToTimeString(dragon.hatchingTime) }}</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Summon Time</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ convertSecondsToTimeString(dragon.breedingTime) }}</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Buy Gold</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ numberFormat(dragon.priceSell) }}</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Sell Gold</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ numberFormat(dragon.priceGold) }}</a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class=" links-widget clearfix">
                                                <div class="widget-content">
                                                    <div class="row clearfix">
                                                        <div class="column col-lg-6 col-md-6 col-sm-12">
                                                            <div class="widget-title">
                                                                <h3>BASIC ATTACK SKILLS</h3>
                                                            </div>
                                                            <table class="tbl-info">
                                                                <tr v-for="skill in dragon.attackSkills" :key="skill.id">
                                                                    <td>
                                                                        <img width="30" :src="`/images/dragons/skills/${skill.element}-big.png`">&nbsp;&nbsp;{{ skill.originalName }}
                                                                    </td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ numberFormat(skill.damage) }}
                                                                            &nbsp;&nbsp;<img width="25" src="/images/common/ic_item_pvp.png">
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div class="column col-lg-6 col-md-6 col-sm-12">
                                                            <div class="widget-title">
                                                                <h3>TRAINABLE ATTACK SKILLS</h3>
                                                            </div>
                                                            <table class="tbl-info">
                                                                <tr v-for="skillId in dragon.trainableAttackSkillsRaw" :key="skillId">
                                                                    
                                                                    <td>
                                                                        <img width="25" src="/images/common/ic_item_pvp.png">&nbsp;&nbsp;{{ skills[skillId] }}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class=" links-widget clearfix">
                                                <div class="widget-content">
                                                    <div class="row clearfix">
                                                        <div class="column col-lg-6 col-md-6 col-sm-12">
                                                            <div class="widget-title">
                                                                <h3>CRITICAL DAMAGE</h3>
                                                            </div>
                                                            <table class="tbl-info">
                                                                <!-- <tr v-for="skill in dragon.attackSkills" :key="skill.id">
                                                                    <td>{{ skill.originalName }}</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ numberFormat(skill.damage) }}
                                                                            &nbsp;&nbsp;<img width="20" src="/images/common/ic_item_pvp.png">
                                                                        </a>
                                                                    </td>
                                                                </tr> -->
                                                            </table>
                                                        </div>
                                                        <div class="column col-lg-6 col-md-6 col-sm-12">
                                                            <div class="widget-title">
                                                                <h3>WEAKNESSES</h3>
                                                            </div>
                                                            <table class="tbl-info">
                                                                <!-- <tr v-for="skill in dragon.attackSkills" :key="skill.id">
                                                                    <td>{{ skill.originalName }}</td>
                                                                    <td>
                                                                        <a href="javascript:void()">{{ numberFormat(skill.damage) }}
                                                                            &nbsp;&nbsp;<img width="20" src="/images/common/ic_item_pvp.png">
                                                                        </a>
                                                                    </td>
                                                                </tr> -->
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>

                                            <!-- Comments Area -->
                                            <div class="comments-area">
                                                <div class="group-title">
                                                    <h3>RELATED DRAGONS</h3>
                                                </div>
                                                <div class="comment-box" v-for="related in relatedDragons" :key="related.id">
                                                    <div class="comment">
                                                        <div class="row">
                                                            <div class="col col-md-2">
                                                                <figure class="thumb">
                                                                    <a :href="`/dragons/${related.urlName.toLowerCase()}`">
                                                                    <div class="dragon-avatar">
                                                                        <img :src="getAvatar(related, 3)" :alt="related.name" :title="related.name" @error="getErrorImg">
                                                                    </div>
                                                                    </a>
                                                                </figure>
                                                            </div>
                                                            <div class="col col-md-4">
                                                                <h4 class="name">
                                                                    <a :href="`/dragons/${related.urlName.toLowerCase()}`">
                                                                        {{ related.name }}
                                                                    </a>
                                                                </h4>
                                                                <span class="">
                                                                    <span v-for="ele in related.dragonAttribute" :key="ele">
                                                                        <img :src="`/images/dragons/elements/${ele.replace('/Content/Images/DragonType/', '')}`" alt="Dragon Element" width="40">
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div class="col col-md-6">
                                                                <span>
                                                                    <a :href="`/dragons/${related.urlName.toLowerCase()}`"><img :src="getAvatar(related, 1)" :alt="related.name" :title="related.name" @error="getErrorImg"></a>
                                                                    <a :href="`/dragons/${related.urlName.toLowerCase()}`"><img :src="getAvatar(related, 2)" :alt="related.name" :title="related.name" @error="getErrorImg"></a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <!--Sidebar Side-->
                    <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
                        <aside class="sidebar">
                            <!-- Post Widget -->
                            <div class="sidebar-widget popular-posts">
                                <div class="widget-content">
                                    <h4 class="sidebar-title">Dragon Elements</h4>
                                    <article class="">
                                        <div class="">
                                            <span v-for="ele in dragon.dragonAttribute" :key="ele">
                                                <img :src="`/images/dragons/elements/${ele.replace('/Content/Images/DragonType/', '')}`" alt="Dragon Element" width="40">
                                            </span>
                                        </div>
                                    </article>
                                </div>
                            </div>

                            <!-- Category Widget -->
                            <div class="sidebar-widget categories">
                                <div class="widget-content">
                                    <h4 class="sidebar-title">RANK - DAMAGE: {{ numberFormat(dragon.maxDamage) }}</h4>
                                    <div class="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
                                        data-wow-delay="0ms" data-wow-duration="1500ms"
                                        v-for="(rank, position) in dragon.rank" :key="rank">
                                        <div class="inner-box">
                                            <div class="icon-box">{{ numberFormat(rank) }}</div>
                                            <h4>{{ position.replace('Rank', '') }}</h4>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Dragon',
        components: {

        },
        props: {
            dragons: Array,
            elements: Array,
            skills: Object,
        },
        data() {
            return {
                dragonName: '',
                dragon: {},
                relatedDragons: []
            }
        },
        mounted() {
            window.scrollTo(0, 200);
        },
        created() {
            this.dragonName = this.$route.params.dragon

            this.dragons.map((drg) => {
                let dragonName = drg.urlName.toLowerCase()
                if (this.dragonName == dragonName) {
                    this.dragon = drg
                    return
                }
            });

            if (undefined == this.dragon.name) {
                window.location.href = "/"
            }

            document.title = `${this.dragon.name} | DragonCityZ`

            document.head.querySelector("meta[name=description]").content = `${
                this.dragon.name
            } Dragon | How to breed ${
                this.dragon.name
            } Dragon ? | DragonCityZ`

            document.head.querySelector("meta[name=title]").content = `${
                this.dragon.name
            } Dragon | How to breed ${
                this.dragon.name
            } Dragon ? | DragonCityZ`

            document.querySelector("link[rel~='icon']").href = this.getAvatar(this.dragon, 1);

            this.getReletedDragons()

        },
        methods: {
            getAvatar(dragon, pos) {
                let name = dragon.image.replace('dragons/', '')
                return `/images/dragons/avatar/${name}_${pos - 1}.png`
            },
            getErrorImg(event) {
                event.target.src = `/images/dragons/avatar/desus.png`
            },
            getReletedDragons() {
                const pos = this.dragons.indexOf(this.dragon)

                for(let i = pos - 1; i < pos + 3; i++) {
                    if (this.dragons[i] && this.dragons[i].id != this.dragon.id) {
                        this.relatedDragons.push(this.dragons[i])
                    }
                }
            },
            numberFormat(value) {
                let val = (value/1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            convertSecondsToTimeString(seconds) {
                var days = Math.floor(seconds / 86400);
                seconds = seconds % 86400;
                var hours = Math.floor(seconds / 3600);
                seconds = seconds % 3600;
                var minutes = Math.floor(seconds / 60);
                seconds = Math.round(seconds % 60);

                var durationPieces = [
                    days + 'd',
                    hours + 'h',
                    minutes + 'm',
                    seconds + 's',
                ].filter(piece => {
                    return piece[0] > 0;
                });

                return durationPieces.join(' ');
            },
        }
    }
</script>